.peredacha__section {
  padding: 30px 0;
}
.peredacha__title {
  color: var(--Brand-Blue, #1d2c49);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 48px;
  line-height: normal;
}

.frame_MDS {
  height: 650px;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .frame_MDS {
    height: 900px !important;
    overflow: hidden;
  }
  .peredacha__title {
    font-size: 30px;
  }
}
