.ended-section {
  margin-bottom: 64px;
}
.ended-section__title {
  color: #000;
  font-size: 32px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ended-section__wrapper {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  background-color: #000;
  overflow: hidden;
}

.ended-section__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1050px) {
  .ended-section__wrapper {
    height: 550px;
  }
}

@media only screen and (max-width: 625px) {
  .ended-section__title {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .ended-section__wrapper {
    height: 500px;
  }
}
