.header-section {
  padding: 30px 0px;
  position: relative;
  /* margin-bottom: 32px;     */
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 900;
  margin-bottom: 32px;
}
.header-active {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.heder__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.heder__logo {
  display: flex;
  align-items: center;
  gap: 30.36px;
  cursor: pointer;
}
.heder__location > a {
  display: flex;
  align-items: center;
}
.header__location-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--Text-Colors-Black, #2a2a30);
}
.heder__nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 492px;
  gap: 10px;
}
.header__navlink {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2a2a30;
}
.header__navlink:hover {
  color: #ba3d4f;
  opacity: 0.9;
}
.header__contact-item {
  display: flex;
  align-items: center;
  gap: 32px;
}
.header__contact-text > a {
  color: #1d2c49;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.header__language {
  position: relative;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: #1d2c49;
  padding: 10px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #f2f5fa;
}
.header__burger {
  display: none;
}
.active {
  color: #ba3d4f !important;
}
.heder__menu {
  position: absolute;
  z-index: 4;
  top: 90px;
  right: 20px;
  overflow: hidden;
  background-color: #fff;
  width: 0;
}
.heder__navmobile-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
.heder__menuclosed {
  width: 430px;
  height: 0 !important;
  transition: height 400ms !important;
}
.header__menuopened {
  width: 430px !important ;
  height: 460px !important;
  transition: height 400ms !important;
}
.heder__mobile {
  padding: 16px;
}
.language__bar {
  position: absolute;
  top: 110%;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 3 !important;
  cursor: pointer;
  transition: height 500ms ease;
}

.language__baritem {
  margin-bottom: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  border: none;
  outline: none;
  color: #1d2c49;
  padding: 5px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #f2f5fa;
  cursor: pointer;
}
.language__baropened {
  width: 100% !important;
  height: 70px !important;
  transition: height 500ms ease;
}

@media only screen and (max-width: 1150px) {
  .heder__nav-list {
    width: 420px;
  }
}

@media only screen and (max-width: 990px) {
  .heder__nav,
  .heder__location,
  .header__contact {
    display: none;
  }
  .header__navlink {
    font-size: 16px;
  }
  .header__burger {
    cursor: pointer;
    display: block;
  }
  .header__location-wrapper {
    margin: 32px 0;
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .header__location-text,
  .header__contact-text,
  .header__language {
    font-size: 16px;
  }
  .header__language {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .header__menuopened {
    right: 0;
    width: 100% !important;
  }
  .heder__menuclosed {
    right: 0;
    width: 100% !important;
  }
}
@media only screen and (max-width: 460px) {
  .heder__menu {
    top: 80px !important;
  }
  .site-logo {
    width: 100.227px;
    height: 30px;
  }
  .header-section {
    padding: 25px 0;
  }
}

.header__location-wrapper {
  position: relative;
}

.header__location-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}
.header__location-wrapper {
  width: 150%;
}

.header__city__text {
  display: block;
  outline: none;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-bottom: 4px;
  padding: 3px 1px;
  border-radius: 4px;
  background: #f2f5fa;
  font-size: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--Text-Colors-Black, #2a2a30);
}

.header__location-wrapper {
  position: relative;
  width: 150px;
  cursor: pointer;
}

.kamhero__wrapiner-img {
  width: 221px;
  height: 221px;
  border-radius: 10px;
}
