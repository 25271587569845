/* CustomVideoPlayer.css */
.custom-video-player-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.play-button {
  position: absolute;
  outline: none;
  border: none;
  background: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.play-button:hover {
  opacity: 0.8;
}
