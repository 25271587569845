.caruselbtn {
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.caruselbtn:hover {
  opacity: 0.8;
}
.aboutcarusel__prev {
  position: absolute;
  top: calc(50% - 24px);
  left: 32px;
  z-index: 3;
}
.aboutcarusel__next {
  position: absolute;
  top: calc(50% - 24px);
  right: 32px;
  z-index: 3;
}
.caruselback {
  background-color: #f2f5fa !important;
}
