.herocarusel {
  width: 100%;
  height: 600px;
  position: relative;
}
.herocarusel__swiper {
  /* width: 100vw; */
  height: 600px;
}
.herocarusel-img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}
.herocarusel__controls {
  width: 100%;
  position: absolute;
  top: calc(50% - 24px);

  z-index: 2;
}
.herocrusel__controls-wrapp {
  position: relative;
}
.herocarusel__prev {
  position: absolute;
  left: -17%;
}
.herocarusel__next {
  position: absolute;
  right: -17%;
}
.herocarusel__next,
.herocarusel__prev {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: #f2f5fa;
  cursor: pointer;
}

.herocarusel__next:hover,
.herocarusel__prev:hover {
  opacity: 0.8;
}
.herocarusel__mobile {
  display: none;
}
@media only screen and (max-width: 1805px) {
  .herocarusel__prev {
    position: absolute;
    left: -12%;
  }
  .herocarusel__next {
    position: absolute;
    right: -12%;
  }
}
@media only screen and (max-width: 1675px) {
  .herocarusel__prev {
    position: absolute;
    left: -9%;
  }
  .herocarusel__next {
    position: absolute;
    right: -9%;
  }
}
@media only screen and (max-width: 1574px) {
  .herocarusel__prev {
    position: absolute;
    left: -6%;
  }
  .herocarusel__next {
    position: absolute;
    right: -6%;
  }
}
@media only screen and (max-width: 1500px) {
  .herocarusel__prev {
    position: absolute;
    left: -4%;
  }
  .herocarusel__next {
    position: absolute;
    right: -4%;
  }
}
@media only screen and (max-width: 1440px) {
  .herocarusel__prev {
    position: absolute;
    left: 0;
  }
  .herocarusel__next {
    position: absolute;
    right: 0;
  }
}

@media only screen and (max-width: 990px) {
  .herocarusel__swiper,
  .herocarusel {
    height: 500px;
  }
}

@media only screen and (max-width: 572px) {
  .herocarusel-img {
    object-fit: initial;
  }
  .herocarusel__mobile {
    display: block !important;
  }
  .herocarusel {
    display: none;
  }
  .herocarusel__swiper,
  .herocarusel {
    height: 400px;
  }
  .herocarusel__swiper-slide {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }
  .homecarusel__prev,
  .homecarusel__next {
    display: none !important;
  }
}
