.footer-section {
  padding: 32px 0 64px 0;
  background: #f2f5fa;
}
.footer__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}

.footer__navlist {
  display: flex;
  align-items: center;
  gap: 64px;
}

.foorter__link {
  color: #1d2c49;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.foorter__link:hover {
  color: #ba3d4f;
}
.footer__copyrigth {
  color: #8c94a8;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .footer__navlist {
    gap: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-section {
    padding: 32px 0;
  }
  .footer__navbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .footer__bottom {
    gap: 18px;
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .foorter__link {
    font-size: 16px;
  }
  .footer__navlist {
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
