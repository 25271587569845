.callcard {
  margin-bottom: 64px;
  position: relative;
}
.callcard__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 96px;
  justify-content: space-between;
  padding: 32px;
  border-radius: 20px;
  border: 1px solid #000;
  background: var(--Brand-Blue, #1d2c49);
}
.callcard__form-title {
  margin-bottom: 20px;
  color: #f2f5fa;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.callcard__form-text {
  margin-bottom: 43px;
  color: #8c94a8;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.callcard__form {
  width: 371px !important ;
}
.callcard__form-form {
  width: 371px;
}
.callcard__smothed {
  display: block;
  position: absolute;
  top: -130px;
}
.callcard__form-label {
  display: block;
  color: #f2f5fa;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form__error {
  margin-top: 5px;
  font-size: 14px;
  color: #ba3d4f;
}
.callcard__form-input {
  width: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  height: 37px;
  border-radius: 180px;
  background: #f2f5fa;
  color: #8c94a8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.callcard__form-select {
  width: 100%;
  height: 37px;
  margin-bottom: 43px;
  padding: 0 20px 0 20px;
  border: none;
  outline: none;
  border-radius: 180px;
  background: #f2f5fa;
  color: #8c94a8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.callcard__form-box {
  margin-bottom: 20px;
}
.callcard__form-button {
  margin-top: 43px;
  border: none;
  outline: none;
  width: 100%;
  height: 37px;
  background: #f2f5fa;
  border-radius: 180px;
  color: #1d2c49;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: normal;
}
.callcard__image {
  width: calc(100% - 410px);
  height: 456px;
  border-radius: 10px;
  overflow: hidden;
}

.callcard__image-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.callcard__form-button:hover {
  opacity: 0.8;
}
.callcard__form-button:active {
  outline: 0.7;
}
.homeyoutube__carusel-item {
  border-radius: 20px !important;
}
@media only screen and (max-width: 890px) {
  .callcard__image {
    display: none !important;
  }
  .callcard__wrapper {
    justify-content: center;
  }
}
@media only screen and (max-width: 625px) {
  .callcard__form-title {
    font-size: 26px;
  }
  .callcard__form-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 572px) {
  .callcard__form {
    width: 100% !important;
  }
  .callcard__form-form {
    width: 100% !important;
  }
}
