@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat"),
    url("./Fonts/Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat"),
    url("./Fonts/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Regular"),
    url("./Fonts/Montserrat-Medium.woff") format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica"), url("./Fonts/Helvetica.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif !important;
}
.container {
  max-width: 1340px;
  padding: 0 20px;
  margin: 0 auto;
}
.brix__callcard .b24-form-wrapper {
  background-color: #1d2c49 !important;
  height: 100%;
}
.brix__callcard .b24-form-padding-side {
  padding: 0 !important;
}
.brix__callcard .b24-form-header-title {
  display: none !important;
}
.brix__callcard .b24-form-header-description {
  display: none !important;
}
.brix__callcard .b24-form-control {
  background-color: white !important;
  margin-bottom: 36px !important;
  border-radius: 120px !important;
  color: var(--Text-Colors-Grey, #8c94a8) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 37px !important;
  padding: 0 20px !important;
}
.brix__callcard .b24-form-control-label {
  top: -20px !important;
  color: var(--Elements-bg-blue, #f2f5fa) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.brix__callcard .b24-form-sign-abuse-help {
  display: none !important;
}
.brix__callcard .b24-form-btn {
  border-radius: 120px !important;
  background: var(--Text-Colors-Light-Blue, #f2f5fa) !important;
  color: var(--Text-Colors-Blue, #1d2c49) !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  height: 37px !important;
}
.brix__callcard .b24-form-control-alert-message {
  top: calc(100% - 30px) !important ;
}
.brix__callcard .b24-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  padding: 0 !important ;
  min-height: 37px !important;
  height: 37px !important;
}
