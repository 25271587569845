.infrasturct {
  margin-bottom: 64px;
}
.shortscarusel__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.infrasturct__title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.infrastruct__wrapper {
  display: flex;
  justify-content: space-between;
}
.infrastruct__box {
  width: 420px;
  padding: 32px;
  border-radius: 20px;
  background: var(--Elements-bg-blue, #f2f5fa);
}
.infrastruct__caruselbox {
  width: calc(100% - 450px);
}
.infrastruct__carusel {
  width: 100%;
  height: 100%;
}
.infrastruct__controler {
  display: flex;
  align-items: center;
  gap: 24px;
}
.infrastruct__slide {
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}
.infrastruct__caruselimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.infrastruct__list {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 36px;
}
.infrastruct__list-title {
  color: var(--Brand-Blue, #1d2c49);
  margin-bottom: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.infrastruct__list-text {
  color: var(--Text-Base, #8c94a8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.infrastruct__btn,
.infrastruct__btn2 {
  display: block;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 120px;
  background: var(--Brand-Blue, #1d2c49);
  color: var(--Elements-bg-blue, #f2f5fa);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.infrastruct__btn2 {
  display: none;
}
.infrastruct__controler2 {
  display: none;
}

@media only screen and (max-width: 920px) {
  .infrastruct__controler {
    display: none;
  }
  .infrastruct__wrapper {
    flex-direction: column;
    gap: 32px;
  }
  .infrastruct__controler2 {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
  }
  .infrastruct__caruselbox {
    width: 100%;
    height: 500px;
  }
  .infrastruct__box {
    width: 100%;
  }
  .infrastruct__btn {
    width: 400px;
  }
  .infrastruct__slide {
    height: 420px !important;
    overflow: hidden;
  }
}

@media only screen and (max-width: 625px) {
  .infrastruct__slide {
    height: 398px !important;
    overflow: hidden;
  }
  .infrasturct__title {
    font-size: 26px;
    margin-bottom: 24px;
  }
  .infrastruct__controler2 {
    display: none;
  }
  .infrastruct__btn {
    display: none;
  }
  .infrastruct__caruselbox {
    height: 400px;
  }
  .infrastruct__btn2 {
    display: block;
    margin-top: 24px;
    margin-bottom: 64px;
  }
  .infrasturct {
    margin-bottom: 104px;
  }
}
