.innerproekt-carusel {
  margin-bottom: 64px;
}
.innerproekt-carusel__wrapper {
  display: flex;
  align-items: flex-end !important;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 32px;
}
.innerproekt-carusel__title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kamvideos__item2 {
  height: 600px;
  width: calc(33% - 20px);
  border-radius: 20px;
  overflow: hidden;
}
.innerproektcarusel__items {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 57.23%,
    rgba(0, 0, 0, 0.8) 80.33%
  );
  align-items: flex-end;
}
.proektitem__causel-img {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.proektitemcarusel__text {
  padding: 32px 16px;
}
.proektitemcarusel__title {
  color: var(--Text-Colors-Light-Blue, #f2f5fa);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
}
.proektitemcarusel__info {
  color: var(--Text-Colors-Light-Blue, #f2f5fa);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.kamvideos__controler {
  display: flex;
  align-items: center;
  gap: 24px;
}

@media only screen and (max-width: 625px) {
  .innerproekt-carusel__title {
    font-size: 26px;
  }
  .innerproekt-carusel__wrapper {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 572px) {
  .kamvideos__controler {
    display: none;
  }
  .kamvideos__item2 {
    height: 500px;
  }
}
