.aboutitem {
  margin-bottom: 64px;
}
.aboutitem__wrapper {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}

.aboutitem__imgbox {
  width: calc(50% - 30px);
  border-radius: 20px;
  overflow: hidden;
  height: 654px;
}
.aboutitem__img {
  height: 100% !important;

  width: 100% !important;
  object-fit: cover;
}

.aboutitem__title {
  color: #000;
  margin-bottom: 18px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.aboutitem__text {
  margin-bottom: 24px;
  color: var(--Text-Base, #8c94a8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aboutitem__innerbox {
  padding: 32px;
  border-radius: 20px;
  background: var(--Background-Colors-Light-Blue, #f2f5fa);
  margin-bottom: 48px;
  color: var(--Text-Colors-Blue, #1d2c49);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aboutitem__box {
  width: calc(50% - 30px);
}
.aboutitem__btn {
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 120px;
  background: var(--Brand-Blue, #1d2c49);
  color: var(--Elements-bg-blue, #f2f5fa);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.aboutitem__imgbox2 {
  display: none;
}
@media only screen and (max-width: 1150px) {
  .aboutitem__imgbox {
    height: 500px;
  }
}
@media only screen and (max-width: 840px) {
  .aboutitem__wrapper {
    flex-direction: column;
  }

  .aboutitem__imgbox2 {
    display: block;
    width: 100%;
    overflow: hidden;
    height: 400px;
    margin-bottom: 24px;
    border-radius: 20px;
  }
  .aboutitem__imgbox {
    display: none;
  }
  .aboutitem__box {
    width: 100%;
  }
  .aboutitem__imgbox {
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 625px) {
  .aboutitem__title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 500px) {
  .aboutitem__btn {
    display: block;
    width: 100% !important;
    text-align: center;
  }
}
