.btirixmodal {
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
}
.btirixmodal__active {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  opacity: 1;
  width: 100% !important;
  top: 0;
  bottom: 0;
  z-index: 100000;
  height: 100vh !important;
}
.btirixmodal__main__inner {
  position: relative;
}
.btrix__closebtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  border: none;
  background: none;
  cursor: pointer;
}
.btirixmodal__inner,
.btirixmodal__main__inner {
  width: 500px !important;
}

@media only screen and (max-width: 800px) {
  .btirixmodal__inner,
  .btirixmodal__main__inner {
    width: 400px !important;
  }
}

@media only screen and (max-width: 500px) {
  .btirixmodal__inner .btirixmodal__main__inner {
    width: 300px !important;
  }
}

@media only screen and (max-width: 400px) {
  .btirixmodal__inner,
  .btirixmodal__main__inner {
    width: auto !important;
  }
}
