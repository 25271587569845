.planirovka {
  margin-top: 64px;
  margin-bottom: 64px;
}
.planirovka__title {
  color: #000;
  margin-bottom: 32px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 625px) {
  .planirovka__title {
    font-size: 26px;
    margin-bottom: 24px;
  }
}
