.drobdown {
  margin-bottom: 64px;
}
.dropdown__wrapper {
  border-radius: 20px;
  background: #f2f5fa;
  padding: 32px;
  margin-bottom: 32px;
}
.drobdown__maintitle {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}
.dropdown__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown__title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dropdown__img {
  cursor: pointer;
}
.dorpdown__imgrotate {
  transform: rotate(-180deg);
  transition: all 0.4s;
}
.dropdown__menunone {
  height: 0 !important;
  overflow: hidden;
}
.dropdown__menublock {
  margin-top: 24px;
  height: 100% !important;
  transition: heigth 3s;
}
.dropdown__menu-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 36px;
}

.drobdown__controler {
  display: flex;
  align-items: center;
  gap: 24px;
}
.drobdown__menuitem {
  max-width: 651px;
}
.drobdown__text {
  color: var(--Text-Colors-Grey, #8c94a8);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}
.drobdown__btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 120px;
  background: var(--Background-Colors-Blue, #1d2c49);
  padding: 12px 24px;
  color: var(--Text-Colors-Light-Blue, #f2f5fa);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.prevss {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #1d2c49;
}
.drobdown__swiper {
  width: 100%;
  height: 420px;
}
.drobdown__item {
  width: calc(33% - 20px);
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.drobdown__item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 625px) {
  .drobdown__text {
    font-size: 16px;
  }
  .drobdown__maintitle {
    font-size: 26px;
  }
  .dropdown__wrapper {
    padding: 24px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 572px) {
  .drobdown__btn {
    width: 100%;
  }
  .drobdown__controler {
    display: none;
  }
  .dropdown__title {
    font-size: 26px;
  }
}
