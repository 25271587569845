.about-proekt {
  margin: 64px 0;
}
.about-proekt__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.about-proekt__box {
  width: calc(50% - 110px) !important;
}
.about-proekt__title {
  margin-bottom: 18px;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about-proekt__text {
  margin-bottom: 32px;
  color: var(--Text-Base, #8c94a8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-proekt__btn {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 120px;
  background: var(--Brand-Blue, #1d2c49);
  color: var(--Elements-bg-blue, #f2f5fa);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.about-proekts__cards {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: calc(50% - 30px);
}
.about-proekt__innerproekt {
  width: 100%;
  padding: 32px;
  min-height: 180px;
  border-radius: 20px;
  background: #f2f5fa;
}
.about-proekt__innerlist {
  display: flex;
  gap: 30px;
}
.about-proekt__inner-title {
  color: var(--Text-Colors-Grey, #8c94a8);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
.about-proekt__inner-abouttext {
  color: var(--Text-Colors-Blue, #1d2c49);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 1150px) {
  .about-proekt__box {
    width: calc(50% - 50px) !important;
  }
}

@media only screen and (max-width: 930px) {
  .about-proekt__box {
    width: 100% !important;
  }
  .about-proekt__wrapper {
    flex-direction: column;
    gap: 32px;
  }
  .about-proekts__cards {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .about-proekt__innerlist {
    flex-direction: column;
  }
}

@media only screen and (max-width: 625px) {
  .about-proekt__title,
  .about-proekt__inner-abouttext {
    font-size: 26px;
  }
  .about-proekts__cards,
  .about-proekt__innerlist {
    gap: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .about-proekt__btn {
    width: 100%;
  }
}
